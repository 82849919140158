<template>
  <div>
    <top-bar />

    <nav-bar />

    <div >
        <div class="inner-banner has-base-color-overlay text-center" style="background: url(/assets/images/background/4.jpg);">
        <div class="container">
          <div class="box">
            <h1> {{$t("_PRODUCTS.LINK_TITLE")}} </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
        <ourProduct></ourProduct>
    </div>

  <footer-top />
  <footer-bottom />

  </div>
</template>
<script>
import ourProduct from '../components/our-products.vue'
export default {
  name: 'Products',
  components: { ourProduct }
}
</script>
