<template>
  <div class="container">
    <div class="section-title center">
        <h2>{{$t("_PRODUCTS.TITLE")}}</h2>
    </div>
    <p>
      {{$t("_PRODUCTS.TEXT")}}
    </p>
    <div class="section-title center">
        <h2>{{$t("_PRODUCTS.SECOND_TITLE")}}</h2>
    </div>
    <div class="cart-section">
      <ul class="totals-table">
        <li class="clearfix"><span class="col col-title">{{$t("_PRODUCTS.TABLE_DATA_SHEET.PRODUCT[0]")}}</span><span class="col">{{$t("_PRODUCTS.TABLE_DATA_SHEET.PRODUCT[1]")}}</span></li>
        <li class="clearfix"><span class="col col-title">{{$t("_PRODUCTS.TABLE_DATA_SHEET.PHYSICAL_STATE[0]")}}</span><span class="col">{{$t("_PRODUCTS.TABLE_DATA_SHEET.PHYSICAL_STATE[1]")}}</span></li>
        <li class="clearfix"><span class="col col-title">{{$t("_PRODUCTS.TABLE_DATA_SHEET.WASH[0]")}} </span><span class="col">{{$t("_PRODUCTS.TABLE_DATA_SHEET.WASH[1]")}}</span></li>
        <li class="clearfix"><span class="col col-title">{{$t("_PRODUCTS.TABLE_DATA_SHEET.ODOR[0]")}} </span><span class="col">{{$t("_PRODUCTS.TABLE_DATA_SHEET.ODOR[1]")}}</span></li>
        <li class="clearfix"><span class="col col-title">{{$t("_PRODUCTS.TABLE_DATA_SHEET.COLOR[0]")}}</span><span class="col">{{$t("_PRODUCTS.TABLE_DATA_SHEET.COLOR[1]")}}</span></li>
      </ul>
    </div>

  <ul class="navigation">
    <li v-for="(product,x) in products" :key="x" :class=" product.show ? 'active' : ''" v-on:click="arrayChange(x)" >{{product.thead[0][0]}}</li>
  </ul>

<table class="table" v-for="(array,r) in products" v-show="array.show" :key="r">
  <thead>
    <tr v-for="(line,i) in array.thead" :key="i">
      <th v-for="(column,k) in line" :key="k" :colspan="(i==0) ? 3 : ''">
        <p :class="(i == 0) ? 'first-thead' : '' ">{{column}}</p>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(line,i) in array.tbody" :key="i">
      <td v-for="(column,k) in line" :key="k">
        <p>{{column}}</p>
      </td>
    </tr>
  </tbody>
</table>
</div>
</template>
<script>
import products from './products.json'
export default {
  name: 'our-product',
  data () {
    return {
      currentLang: this.$cookie.get('locale') || 'En'
    }
  },
  created () {
    if (this.currentLang === 'fr') {
      this.products = products.fr
    } else {
      this.products = products.en
    }
  },
  methods: {
    arrayChange (index) {
      for (const key in this.products) {
        if (key === index) {
          this.products[key].show = true
        } else {
          this.products[key].show = false
        }
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
  h2{
    margin: 20px 0;
  }
  .table{
    margin: 20px 0;
    border: 2px solid #f4f4f4;
  }
  .table th , .table td{
    padding: 14.5px 15px;
  }
  .table p{
    font-size: 17px !important;
  }
  .table thead th p{
    color : black;
    font-weight: bold;
  }
  p.first-thead{
    text-align : center;
  }

  .navigation{
    padding: 0 10px;
    min-height: 60px;
    background: #7dd600;
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .navigation li{
    margin: 10px auto;
    font-size: 15px;
    font-weight: bold;
    color :white;
    transition: .2s linear;
  }
  .navigation li.active{
    height: 100%;
    font-size: 17px;
    border-bottom: 2px solid white ;
  }
  .navigation li:hover{
    font-size: 17px;
    transition: .2s linear;
    cursor: pointer;
  }

</style>
