<template>
<div class="boxed_wrapper">

  <top-bar />

  <nav-bar />

  <!--Start rev slider wrapper-->
  <slider />
  <!--End rev slider wrapper-->

  <about />

  <facts />

  <div class="container">
    <div class="section-title ourProduct center">
      <h2>{{$t("_PRODUCTS.TITLE")}}</h2>
      <p>
        {{$t("_PRODUCTS.TEXT")}}
      </p>
      <a href="/products">{{ $t("_PRODUCTS.MORE") }}</a>
    </div>
  </div>

  <galery />

  <process />

  <events />
  <section class="home-google-map">

    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12869.693121923856!2d1.9364539!3d36.2535013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x954b5da30c8c6ac7!2sAFC%20RECYCLAGE!5e0!3m2!1sfr!2sdz!4v1610981480991!5m2!1sfr!2sdz" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

  </section>

  <div class="border-bottom"></div>

  <!-- <partners /> -->

  <section class="call-out">
    <div class="container">
      <div class="float_left">
      </div>
    </div>
  </section>

  <footer-top />
  <footer-bottom />

  <!-- Scroll Top  -->
  <button class="scroll-top tran3s color2_bg"><span class="fa fa-angle-up"></span></button>
  <!-- preloader  -->
  <!-- <div class="preloader"></div> -->
</div>
</template>

<script>
import Slider from '../components/slider'
import About from '../components/about'
import Facts from '../components/facts'
import Galery from '../components/galery'
import Process from '../components/process'
import Events from '../components/events'
// import Partners from '../components/partners'

export default {
  name: 'Home',
  components: { Slider, About, Facts, Galery, Process, Events },
  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      '/assets/js/custom.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
</script>

<style scoped>
  .ourProduct h2 {
    margin: 30px 0;
  }
  .ourProduct p{
    margin-bottom: 40px;
  }
  .ourProduct a{
    color: #7DD600;
    margin-bottom: 40px;
  }
</style>
