<template>
<div class="boxed_wrapper">

  <top-bar />

  <nav-bar />

  <div class="inner-banner has-base-color-overlay text-center" style="background: url(/assets/images/background/4.jpg);">
    <div class="container">
      <div class="box">
        <h1>Services</h1>
      </div>
    </div>
  </div>
  <div class="breadcumb-wrapper">
    <div class="container">
      <div class="pull-left">
        <ul class="list-inline link-list">
          <li>
            <a href="index.html">Home</a>
          </li>
          <li>
            Services
          </li>
        </ul>
      </div>
      <div class="pull-right">
      </div>
    </div>
  </div>

  <section class="default-section sec-padd">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12">
          <div class="default-sidebar">
            <ul class="service-catergory">
              <li><a href="recycling.html">Recycling</a></li>
              <li class="active"><a href="Eco-System.html">Eco System</a></li>
              <li><a href="Save-Water.html">Save Water</a></li>
              <li><a href="Save-Animals.html">Save Animals</a></li>
              <li><a href="Organic-Living.html">Organic Living</a></li>
              <li><a href="Good-Nature.html">Good Nature</a></li>
            </ul>

          </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-12">
          <div class="single-service">
            <div class="row mb-30">
              <div class="col-md-5 col-sm-5 col-xs-12">
                <div class="img-box">
                  <img src="/assets/images/service/1.jpg" alt="AFC Recyclage">
                </div>
              </div>
              <div class="col-md-7 col-sm-7 col-xs-12">
                <h2>ECO System</h2><br>
                <div class="text">
                  <p>There anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage.</p><br>
                  <p>Who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces annoying consequences resultant pleasure.</p>
                </div>
              </div>
            </div>
            <div class="row mb-30">
              <div class="col-md-5 col-sm-5 col-xs-12">
                <div class="img-box">
                  <img src="/assets/images/service/2.jpg" alt="AFC Recyclage">
                </div>
              </div>
              <div class="col-md-7 col-sm-7 col-xs-12">
                <h2>Who Are We Working For?</h2><br>
                <div class="text">
                  <p>Occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever physical exercise, except to obtain some advantage.</p>
                </div><br>
                <ul class="default-list">
                  <li><i class="fa fa-check-circle"></i>Sponsor meals for 50 children for 1 full year.</li>
                  <li><i class="fa fa-check-circle"></i>Sponsor mid-day meals for one month.</li>
                  <li><i class="fa fa-check-circle"></i>You can donate clothes, blankets and ect...</li>
                  <li><i class="fa fa-check-circle"></i>You can donate food material like rice, veggies.</li>
                </ul>
              </div>
            </div><br><br>

          </div>
        </div>

      </div>
    </div>
  </section>

  <section class="call-out">
    <div class="container">
      <div class="float_left">
        <h4>Join Our Mission to Improve a Child's Feature, Pet’s Life and Our Planet.</h4>
      </div>
      <div class="float_right">
        <a href="#" class="thm-btn style-3">Get Involeved</a>
      </div>

    </div>
  </section>

  <footer-top />
  <footer-bottom />

  <!-- Scroll Top  -->
  <button class="scroll-top tran3s color2_bg"><span class="fa fa-angle-up"></span></button>
  <!-- preloader  -->
  <!-- <div class="preloader"></div> -->
</div>
</template>

<script>
export default {
  name: 'contact',
  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      '/assets/js/custom.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
</script>
