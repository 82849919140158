<template>
<section class="fact-counter style-2 sec-padd" style="background-image: url(/assets/images/background/5.jpg);">
  <div class="container">
    <div class="section-title center">
      <h2> {{ $t('_FACTS.TITLE') }} </h2>
      <p style="font-size: 16px;margin-top: 12px;" v-html="$t('_FACTS.TEXT')"> </p>
    </div>
    <div class="row clearfix">
      <div class="counter-outer clearfix">
        <article class="column counter-column col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-duration="0ms">
          <div class="item">
            <div class="icon"><i class="icon-people3"></i></div>
            <div class="count-outer"><span class="count-text" data-speed="3000" data-stop="120">0</span>+</div>
            <h4 class="counter-title"> {{ $t('_FACTS.WORKERS.TITLE') }} </h4>
          </div>

        </article>

        <article class="column counter-column col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-duration="0ms">
          <div class="item">
            <div class="icon"><i class="icon-people3"></i></div>
            <div class="count-outer"><span class="count-text" data-speed="3000" data-stop="1000 ">0</span> +</div>
            <h4 class="counter-title"> {{ $t('_FACTS.DIRECT_WORKS.TITLE') }} </h4>
          </div>
        </article>

        <article class="column counter-column col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-duration="0ms">
          <div class="item">
            <div class="icon">
              <!-- <i class="icon-people3"></i> -->
               <img src="/img/delivery.svg" style="width: 40px;" alt="">
              </div>
            <div class="count-outer"><span class="count-text" data-speed="3000" data-stop="600">0</span>+</div>
            <h4 class="counter-title"> {{ $t('_FACTS.TONESPERMONTH.TITLE') }} </h4>
          </div>
        </article>

        <article class="column counter-column col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-duration="0ms">
          <div class="item">
            <div class="icon">
              <!-- <i class="icon-people3"></i> -->
              <img src="/img/soda.svg" style="width: 40px;" alt="">
              </div>
            <div class="count-outer"><span class="count-text" data-speed="3000" data-stop="20">0</span>M+</div>
            <h4 class="counter-title"> {{ $t('_FACTS.MILLIONBOUTLES.TITLE') }} </h4>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'facts'
}
</script>

<style scoped>

</style>
