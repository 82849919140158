<template>
<div class="boxed_wrapper">

  <top-bar />

  <nav-bar />

  <div class="inner-banner has-base-color-overlay text-center" style="background: url(/assets/images/background/4.jpg);">
    <div class="container">
      <div class="box">
        <h1> {{ $t('CONTACT') }} </h1>
      </div>
    </div>
  </div>
  <div class="breadcumb-wrapper">
    <div class="container">
      <div class="pull-left">
        <ul class="list-inline link-list">
          <li>
             <a href="/"> {{ $t('HOME') }} </a>
          </li>
          <li>
            {{ $t('CONTACT') }}
          </li>
        </ul>
      </div>
      <div class="pull-right">
      </div>
    </div>
  </div>

  <!--Start contact area-->
  <section class="get-touch-area">
    <div class="container">
      <div class="sec-title text-center">
        <h1> {{ $t('_CONTACT_US') }} </h1>
        <span class="border"></span>
      </div>
      <div class="row">
        <!--Start single item-->
        <div class="col-md-4">
          <div class="single-item hvr-grow-shadow text-center">
            <div class="icon-holder">
              <span class="icon-signs2"></span>
            </div>
            <div class="text-holder">
              <h3> {{ $t('_COME_SEE_US') }} </h3>
              <span class="border"></span>
              <p v-html="$t('_ADDRESS')"> </p>
            </div>
          </div>
        </div>
        <!--End single item-->
        <!--Start single item-->
        <div class="col-md-4">
          <div class="single-item hvr-grow-shadow text-center">
            <div class="icon-holder">
              <span class="icon-technology"></span>
            </div>
            <div class="text-holder">
              <h3> {{ $t('_QUICK_CONTACT') }} </h3>
              <span class="border"></span>
              <p>Tel: +213 540 07 02 02 <br>Email: <a href="#">info@afcrecyclage-dz.com</a></p>
            </div>
          </div>
        </div>
        <!--End single item-->
        <!--Start single item-->
        <div class="col-md-4">
          <div class="single-item hvr-grow-shadow text-center">
            <div class="icon-holder">
              <span class="icon-clock"></span>
            </div>
            <div class="text-holder">
              <h3> {{ $t('_WORKING_HOURS') }} </h3>
              <span class="border"></span>
              <p v-html="$t('_TIMES')"> </p>
            </div>
          </div>
        </div>
        <!--End single item-->
      </div>
    </div>
  </section>
  <!--End contact area-->

  <!--Start contact form area-->
  <section class="contact-form-area">
    <div class="container">
      <div class="row">

        <div class="col-md-12">
          <div class="default-form-area">
            <form id="contact-form" name="contact_form" class="default-form" action="http://afcrecyclage-dz.com/sendmail.php" method="POST" >
              <div class="row clearfix">
                <div class="col-md-6 col-sm-6 col-xs-12">

                  <div class="form-group">
                    <input type="text" name="name" class="form-control"  :placeholder="$t('_FULL_NAME')" required="" v-model="name" >
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <input type="email" name="from" class="form-control required email" v-model="from" :placeholder="$t('_EMAIL')" required="">
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <input type="text" name="phone" class="form-control" v-model="phone" :placeholder="$t('_PHONE')">
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <input type="text" name="subject" class="form-control" v-model="subject" :placeholder="$t('_SUBJECT')">
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <textarea name="message" class="form-control textarea required" v-model="message" :placeholder="$t('_MESSAGE')"></textarea>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group center">
                    <button class="thm-btn style-2" id="button-submit" type="submit"  v-on:click="sendMail"  >{{ $t('_SUBMIT') }}</button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </section>
  <!--End contact form area-->

  <section class="home-google-map">

    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12869.693121923856!2d1.9364539!3d36.2535013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x954b5da30c8c6ac7!2sAFC%20RECYCLAGE!5e0!3m2!1sfr!2sdz!4v1610981480991!5m2!1sfr!2sdz" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

  </section>

  <section class="call-out">
    <div class="container">
      <div class="float_left">
      </div>

    </div>
  </section>

  <footer-top />
  <footer-bottom />

  <!-- Scroll Top  -->
  <button class="scroll-top tran3s color2_bg"><span class="fa fa-angle-up"></span></button>
  <!-- preloader  -->
  <!-- <div class="preloader"></div> -->
</div>
</template>

<script>

import jquery from 'jquery'

export default {
  name: 'contact',
  data () {
    return {
      from: '',
      mail: '',
      phone: '',
      subject: '',
      message: ''
    }
  },
  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      '/assets/js/custom.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  },
  methods: {
    sendMail (event) {
      jquery('#button-submit').after(' <div class="alert alert-success" id="alert-send" role="alert">' + this.$i18n.t('CONTACT_SEND.SUCCESS') + '</div>')
      window.setTimeout(function () {
        jquery('#alert-send').fadeOut(1000, function () {
          jquery('#alert-send').remove()
        })
      }, 2000)
    }
  }
}
</script>
