<template>
  <footer class="main-footer">

    <!--Widgets Section-->
    <div class="widgets-section">
      <div class="container">
        <div class="row">
          <!--Big Column-->
          <div class="big-column col-md-6 col-sm-12 col-xs-12">
            <div class="row clearfix">

              <!--Footer Column-->
              <div class="col-md-7 col-sm-7 col-xs-12">

                <div class="footer-widget about-column">
                  <figure class="footer-logo"><a href="/"><img src="/img/logo-light.png" alt="AFC Recyclage" style="height: 200px;"></a></figure>
                  <!-- <h1 style="color: #fff">AFC LOGO</h1> -->

                  <div class="text">
                    <p> {{ $t('_ABOUT.TEXT1') }}  </p>
                  </div>
                  <!-- <ul class="contact-info">
                    <li><span class="icon-signs"></span>Rue Emir khaled cite nedjma bloc c rdc , <br>Ain Defla (44000), Algérie</li>
                    <li><span class="icon-phone-call"></span> Phone: +123 xxx xxx xxx </li>
                    <li><span class="icon-note"></span> contact@afcrecyclage-dz.com  | info@afcrecyclage-dz.com</li>
                  </ul> -->
                </div>
              </div>
              <!--Footer Column-->
              <div class="col-md-5 col-sm-5 col-xs-12">
                <div class="footer-widget link-column">
                  <div class="section-title">
                    <h4> {{ $t('_QUICK_LINKS') }} </h4>
                  </div>
                  <div class="widget-content">
                    <ul class="list">
                      <li><a href="/"> {{ $t('HOME') }} </a></li>
                      <li><a href="/about"> {{ $t('ABOUT') }} </a></li>
                      <li><a href="#galery" v-smooth-scroll v-if="isHome"> {{ $t('GALERY') }} </a></li>
                      <li><a href="#events" v-smooth-scroll v-if="isHome"> {{ $t('EVENTS') }} </a></li>
                      <li><a href="/products"> {{ $t('_PRODUCTS.LINK_TITLE') }} </a></li>
                      <li><a href="/contact"> {{ $t('CONTACT') }} </a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Big Column-->
          <div class="big-column col-md-6 col-sm-12 col-xs-12">
            <div class="row clearfix">

              <!--Footer Column-->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="footer-widget post-column">
                  <div class="section-title">
                    <h4> {{ $t('EVENTS') }} </h4>
                  </div>
                  <div class="post-list">
                    <div class="post" v-for="i in num" :key="i">
                      <div class="post-thumb" v-if="events[i].img != '' "><a href="#"><img :src="events[i].img" alt="AFC Recyclage"></a></div>
                      <a href="#">
                        <h5> {{ events[i].title }} </h5>
                      </a>
                      <div class="post-info" v-if="events[i].date != ''"><i class="fa fa-calendar"></i> {{ events[i].date }} </div>
                    </div>
                  </div>

                </div>
              </div>

              <!--Footer Column-->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="footer-widget contact-column">
                  <div class="section-title">
                    <h4> {{ $t('CONTACTUS') }} </h4>
                  </div>
                  <div class="footer-widget about-column">
                  <ul class="contact-info">
                    <li><span class="icon-signs"></span><p v-html="$t('_ADDRESS')">  </p></li>
                    <li><span class="icon-phone-call"></span> {{ $t('_TEL') }}: +213 540 07 02 02 </li>
                    <li><span class="icon-note"></span> contact@afcrecyclage-dz.com  | info@afcrecyclage-dz.com</li>
                  </ul>
                </div>
                  <ul class="social-icon" style="margin-left: 40px;">
                    <li><a href="https://www.facebook.com/AFCGROUPE"><i class="fa fa-facebook"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import evnts from './events.json'
export default {
  name: 'footer-top',
  data () {
    return {
      currentLang: this.$cookie.get('locale') || 'fr',
      events: [],
      num: [0, 1],
      isHome: false
    }
  },
  created () {
    if (this.currentLang === 'fr') {
      this.events = evnts.eventsfr
    } else {
      this.events = evnts.eventsen
    }
    if (window.location.pathname === '/') {
      this.isHome = true
    }
  }
}
</script>

<style>

</style>
