<template>
  <div class="top-bar">
    <div class="container">
      <div class="clearfix">

        <ul class="float_left top-bar-info">
          <li><i class="icon-phone-call"></i>Phone: 00 213 540 07 02 02</li>
          <li><i class="icon-e-mail-envelope"></i>contact@afcrecyclage-dz.com</li>
        </ul>
        <div class="right-column float_right">
          <div class="" style="display: inline-block;">
            <form action="#">
              <select @change="setLocale()" id="polyglot-language-options">
                <option value="en" :selected="currentLang === 'en'">English</option>
                <option value="fr" :selected="currentLang === 'fr'">Français</option>
              </select>
            </form>
          </div>
          <ul class="social list_inline">
            <li><a href="https://www.facebook.com/AFCGROUPE"><i class="fa fa-facebook"></i></a></li>
          </ul>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
const localeStrings = {
  en: 'English',
  fr: 'Français'
}

export default {
  name: 'top-bar',
  data () {
    return {
      currentLang: this.$cookie.get('locale') || 'En'
    }
  },
  methods: {
    setLocale (locale) {
      if (event.target) {
        locale = event.target.value
      }
      // if (locale === 'ar') {
      //   document.body.classList.add('rtl')
      //   document.querySelector('html').setAttribute('dir', 'rtl')
      //   // dir="rtl"
      // } else {
      //   document.body.classList.remove('rtl')
      //   document.querySelector('html').setAttribute('dir', '')
      // }
      this.currentLang = locale
      this.$cookie.set('locale', locale)
      this.$i18n.locale = this.currentLang
      console.log('New locale = ' + this.currentLang + ': language = ' + localeStrings[this.currentLang])
      // this.$toastr.i(localeStrings[this.currentLang], this.$i18n.t('_LANGUE_CHANGED_TO') + ':')
      window.location.reload()
    },
    getLanguageString () {
      return localeStrings[this.currentLang]
    },
    logout () {
      this.$cookie.set('isLoggedIn', false)
      window.location = '/login'
    }
  }
}
</script>

<style>

</style>
