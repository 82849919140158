<template>
<section class="rev_slider_wrapper">
  <div id="slider1" class="rev_slider" data-version="5.0">
    <ul>

      <li data-transition="fade">
        <img src="/assets/images/slider/1.jpg" alt="AFC Recyclage" width="1920" height="888" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption  tp-resizeme" data-x="left" data-hoffset="15" data-y="top" data-voffset="260" data-transform_idle="o:1;" data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-start="700">
          <div class="slide-content-box">
            <h1 v-html="$t('SLIDERS.SLIDER1.TITLE')"> </h1>
            <h3> {{ $t('SLIDERS.SLIDER1.SUBTITLE') }} </h3>
            <p> {{ $t('SLIDERS.SLIDER1.TEXT') }} </p>
          </div>
        </div>
        <div class="tp-caption tp-resizeme" data-x="left" data-hoffset="15" data-y="top" data-voffset="480" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-start="2300">
          <div class="slide-content-box">
            <div class="button">
              <a class="thm-btn" href="/about"> {{ $t('_READ_MORE') }} </a>
            </div>
          </div>
        </div>
      </li>
      <li data-transition="fade">
        <img src="/assets/images/slider/2.jpg" alt="AFC Recyclage" width="1920" height="580" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption  tp-resizeme" data-x="center" data-hoffset="" data-y="top" data-voffset="275" data-transform_idle="o:1;" data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-start="700">
          <div class="slide-content-box center">
            <h1 v-html="$t('SLIDERS.SLIDER2.TITLE')"> </h1>
            <p> {{ $t('SLIDERS.SLIDER2.TEXT') }} </p>
          </div>
        </div>
        <div class="tp-caption tp-resizeme" data-x="center" data-hoffset="-90" data-y="top" data-voffset="450" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-start="2300">
          <div class="slide-content-box">
            <div class="button">
              <a class="thm-btn" href="/about"> {{ $t('_READ_MORE') }} </a>
            </div>
          </div>
        </div>
      </li>
      <li data-transition="fade">
        <img src="/assets/images/slider/3.jpg" alt="AFC Recyclage" width="1920" height="888" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1">

        <div class="tp-caption  tp-resizeme" data-x="left" data-hoffset="500" data-y="top" data-voffset="260" data-transform_idle="o:1;" data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-start="700">
          <div class="slide-content-box">
            <h1 v-html="$t('SLIDERS.SLIDER3.TITLE')"> </h1>
            <h3> {{ $t('SLIDERS.SLIDER3.SUBTITLE') }} </h3>
            <p> {{ $t('SLIDERS.SLIDER3.TEXT') }} </p>
          </div>
        </div>
        <div class="tp-caption tp-resizeme" data-x="left" data-hoffset="500" data-y="top" data-voffset="480" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-splitin="none" data-splitout="none" data-responsive_offset="on" data-start="2300">
          <div class="slide-content-box">
            <div class="button">
              <a class="thm-btn" href="/about"> {{ $t('_READ_MORE') }} </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</section>
</template>

<script>
export default {
  name: 'slider'
}
</script>

<style>

</style>
