<template>
  <section class="theme_menu stricky">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="main-logo">
            <a href="/"><img src="/img/logo-dark.jpg" alt="AFC Recyclage" style="height: 100px;"></a>
            <!-- <h1>AFC LOGO</h1> -->
          </div>
        </div>
        <div class="col-md-9 menu-column">
          <nav class="menuzord" id="main_menu">
            <ul class="menuzord-menu">
              <li><a href="/"> {{ $t('HOME') }} </a></li>
              <li><a href="/about"> {{ $t('ABOUT') }} </a></li>
              <li><a href="#galery" v-smooth-scroll v-if="isHome"> {{ $t('GALERY') }} </a></li>
              <li><a href="#events" v-smooth-scroll v-if="isHome"> {{ $t('EVENTS') }} </a></li>
              <li><a href="/products"> {{ $t('_PRODUCTS.LINK_TITLE') }} </a></li>
              <li><a href="/contact"> {{ $t('CONTACT') }} </a></li>
            </ul>
          </nav>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'nav-bar',
  data () {
    return {
      isHome: false
    }
  },
  created () {
    if (window.location.pathname === '/') {
      this.isHome = true
    }
  }
}

</script>

<style>

</style>
