<template>
  <section class="about sec-padd2">
    <div class="container">
      <div class="section-title center">
        <h2> {{ $t('_WORDS_ABOUT_US') }} </h2>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <figure class="img-box">
            <!-- <img src="/img/afc/P6260168.jpg" alt="AFC Recyclage" style="height: 360px;"> -->
            <!--<iframe src="/film.mp4" width="570" height="350" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe> -->
            <video width="570" height="350" controls>
              <source src="/vid/outputFilm.webm" type="video/webm">
            </video>
          </figure>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="content">
            <h2> {{ $t('_ABOUT.TITLE') }} </h2>
            <div class="text">
              <p> {{ $t('_ABOUT.TEXT1') }} </p>
            </div>
            <div class="text">
               <p> {{ $t('_ABOUT.TEXT2') }} </p>
            </div>
            <div class="button">
                <a class="thm-btn" href="/about" style="    margin-right: 10px;">  {{ $t('_READ_MORE') }}  </a>
                <a href="/contact" class="thm-btn style-2"> {{ $t('CONTACTUS') }} </a>
              </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'about'
}
</script>

<style>

</style>
