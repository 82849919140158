<template>
<section id="events" class="event-style1" style="background-image: url(/assets/images/background/3.jpg);">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-sm-10 col-xs-12">
        <div class="section-title">
          <h2> {{ $t('EVENTS') }} </h2>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-2 col-xs-12">
        <a href="#" class="thm-btn style-2 float_right">All Events</a>
      </div> -->
    </div>
    <div class="row">

      <article class="col-md-6 col-sm-12 col-xs-12" v-for="i in num" :key="i">
        <div class="item style-1" v-if="i==0">
            <div class="img-column">
              <figure class="img-holder">
                <a href="#">
                  <video controls v-if="events[i].vid != null" style="width:100%;height:auto">
                    <source :src="events[i].vid" type="video/mp4">
                  </video>
                  <img v-else :src="events[i].img" alt="AFC Recyclage">
                </a>
              </figure>
            </div>
            <div class="text-column">
              <div class="lower-content">
                <a href="#">
                 <h4> {{ events[i].title }} </h4>
                </a>
                <div class="text">
                  <p>  {{ events[i].desc }} </p>
                </div>
              </div>
              <ul class="post-meta list_inline">
                <li v-if="events[i].date != '' "><a style="color: #88be3d;" :href="events[i].gmap"> <i class="fa fa-clock-o"></i> {{ events[i].date }}  </a></li> <!--|&nbsp;&nbsp;&nbsp;
                <li> <a style="color: #88be3d;" :href="events[i].gmap"> <i class="fa fa-map-marker"></i> {{ events[i].place }} </a></li>-->
              </ul>
            </div>
        </div>
        <div class="item style-2" v-else>
          <div class="clearfix">
            <div class="img-column">
              <figure class="img-holder">
                <a href="#">
                  <video controls v-if="events[i].vid != null" style="width:100%;height:auto">
                    <source :src="events[i].vid" type="video/mp4">
                  </video>
                  <img v-else :src="events[i].img" alt="AFC Recyclage">
                </a>
              </figure>
            </div>
            <div class="text-column">
              <div class="lower-content">
                <a href="#">
                 <h4> {{ events[i].title }} </h4>
                </a>
                <div class="text">
                  <p>  {{ events[i].desc }} </p>
                </div>
              </div>
              <ul class="post-meta list_inline">
                <li v-if="events[i].date != '' "><a style="color: #88be3d;" :href="events[i].gmap"> <i class="fa fa-clock-o"></i> {{ events[i].date }}  </a></li> <!--|&nbsp;&nbsp;&nbsp;
                <li> <a style="color: #88be3d;" :href="events[i].gmap"> <i class="fa fa-map-marker"></i> {{ events[i].place }} </a></li>-->
              </ul>
            </div>
          </div>
        </div>

      </article>

      </div>
  </div>
</section>
</template>

<script>
import evnts from './events.json'
export default {
  name: 'events',
  data () {
    return {
      currentLang: this.$cookie.get('locale') || 'fr',
      events: [],
      num: [0, 1, 2]
    }
  },
  created () {
    if (this.currentLang === 'fr') {
      this.events = evnts.eventsfr
    } else {
      this.events = evnts.eventsen
    }
  }
}
</script>

<style>

</style>
