<template>
  <section class="footer-bottom">
    <div class="container">
      <div class="pull-left copy-text">
        <p v-html="$t('_COPYRIGHTS')">  </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'footer-bottom'
}
</script>

<style>

</style>
