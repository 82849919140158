import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import TopBar from './components/top-bar'
import NavBar from './components/nav-bar'
import FooterTop from './components/footer-top'
import FooterBottom from './components/footer-bottom'

// Lang
import VueI18n from 'vue-i18n'
import fr from './assets/i18n/fr.json'
import en from './assets/i18n/en.json'
import VueCookie from 'vue-cookie'
import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

Vue.use(VueCookie)
Vue.use(VueI18n)

Vue.component('top-bar', TopBar)
Vue.component('nav-bar', NavBar)
Vue.component('footer-top', FooterTop)
Vue.component('footer-bottom', FooterBottom)

Vue.config.productionTip = false

// change  traduction file lang
const i18n = new VueI18n({
  locale: VueCookie.get('locale') || 'en',
  fallbackLocale: 'en',
  messages: {
    fr: fr,
    en: en
  }
})

new Vue({

  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
