<template>
<section id="galery" class="gallery sec-padd3 style-2 default-gallery" style="background-image: url(/assets/images/background/8.jpg);">
  <div class="container">
    <div class="section-title">
      <h2> {{ $t('_GALERY.TITLE') }} </h2>
    </div>
    <ul class="post-filter style-3 list-inline float_right">
    <!--   <li  data-filter=".filter-item">
        <span> {{ $t('VIEW_ALL') }} </span>
      </li> -->
      <li class="active" data-filter=".Factory">
        <span id="galery-nav-factory" > {{ $t('FACTORY') }} </span>
      </li>
      <li  data-filter=".Product">
        <span> {{ $t('PRODUCT') }} </span>
      </li>
    </ul>

    <div class="row filter-layout" id="gridGalery">
      <article class="col-md-3 col-sm-6 col-xs-12  Factory " v-for="(image,key) in UsineImage" :key="key+'u'">
        <div class="item">
          <div class="img-box">
            <img :src="'/img/afc/Usine/'+image" class="g-img " alt="AFC Recyclage">
            <div class="overlay">
              <div class="inner-box">
                <div class="content-box">
                  <a data-group="3" :href="'/img/afc/Usine/'+image" class="img-popup"><i class="fa fa-search-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article class="col-md-3 col-sm-6 col-xs-12  Product " style="position: absolute; left: 0px; top: 0px; display: none;" v-for="(image,key) in ProduitImage" :key="key+'p'">
        <div class="item">
          <div class="img-box">
            <img :src="'/img/afc/Products/'+image" class="g-img " alt="AFC Recyclage">
            <div class="overlay">
              <div class="inner-box">
                <div class="content-box">
                  <a data-group="3" :href="'/img/afc/Products/'+image" class="img-popup"><i class="fa fa-search-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <!-- <article class="col-md-3 col-sm-6 col-xs-12 filter-item Wild-Animals Pollution Water">
        <div class="item">
          <div class="img-box">
            <img src="/img/afc/P6260258.jpg" class="g-img" alt="AFC Recyclage">
            <div class="overlay">
              <div class="inner-box">
                <div class="content-box">
                  <a data-group="1" href="/img/afc/P6260258.jpg" class="img-popup"><i class="fa fa-search-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article> -->
    </div>
  </div>
</section>
</template>

<script>

export default {
  name: 'galery',
  data () {
    return {
      UsineImage: ['gelery_3.jpg', 'A72A1397.jpg', 'A72A1381.jpg', 'A72A1408.jpg', 'A72A1443.jpg', 'A72A1433.jpg', 'A72A1715.jpg', 'A72A1729.jpg', 'A72A1578.jpg', 'A72A1464.jpg', 'A72A1453.jpg', 'A72A1675.jpg'],
      ProduitImage: ['P6260148.jpg', 'A72A1404.jpg', 'A72A1499.jpg', 'A72A1607.jpg', 'P6260382.jpg', 'A72A1384.jpg', 'A72A1697.jpg', 'A72A1451.jpg', 'A72A1467.jpg', 'P6240019.jpg', 'A72A1750.jpg', 'A72A1759.jpg']
    }
  }

}
</script>

<style scoped>
  .g-img {
    min-height: 200px;
    max-height: 200px;
  }

</style>
